<template>
  <div>
    <el-popover
      placement="top-start"
      width="300"
      trigger="hover"
      class="rev-popover-container"
    >
      <!-- 省略内容 -->
      <div class="rev-popover">
        <el-row class="rev-row">
          <el-col :span="8"
            ><h4>{{ revModel.revText }}:</h4></el-col
          >
          <el-col :span="16">{{ versionInfo.revNumber }}</el-col>
        </el-row>
        <el-row class="rev-row">
          <el-col :span="8"
            ><h4>{{ revModel.createByText }}:</h4></el-col
          >
          <el-col :span="8">{{ versionInfo.createName }} </el-col>
          <el-col :span="8">{{ versionInfo.createTime }} </el-col>
        </el-row>
        <el-row class="rev-row">
          <el-col :span="8"
            ><h4>{{ revModel.updatedByText }}:</h4></el-col
          >
          <el-col :span="8">{{ versionInfo.updateName }}</el-col>
          <el-col :span="8">{{ versionInfo.updateTime }}</el-col>
        </el-row>
        <el-row class="rev-row" v-if="pageStatus == 'Detail'">
          <el-col :span="8"
            ><h4>{{ revModel.verifiedByText }}:</h4></el-col
          >
          <el-col :span="8">{{ versionInfo.verifiedName }} </el-col>
          <el-col :span="8">{{ versionInfo.verifiedTime }}</el-col>
        </el-row>
      </div>
      <el-button slot="reference" class="revInfo">{{
        revModel.revInfoText
      }}</el-button>
    </el-popover>
  </div>
</template>
<script>
export default {
  data() {
    return {
      revision: {},
    };
  },
  props: {
    pageStatus: String,
    versionInfo: Object,
    language: String,
  },
  computed: {
    revModel: function () {
      if (this.language == "CN") {
        return {
          revText: "版本",
          createByText: "创建",
          updatedByText: "编辑",
          verifiedByText: "审核",
          revInfoText: "修订信息",
        };
      }
      return {
        revText: "Rev",
        createByText: "Created By",
        updatedByText: "Updated By",
        verifiedByText: "Verified By",
        revInfoText: "Rev Info",
      };
    },
  },
  created() {},
  methods: {},
};
</script>
