<template>
  <el-checkbox
    v-model="item.checked"
    @change="handleChange"
    :disabled="item.disabled"
    style="float: left"
  ></el-checkbox>
</template>

<script>
export default {
  props: {
    item: Object,
    value: String,
  },
  methods: {
    handleChange(val) {
      console.info("handleChange:", val);
      this.item.value = val;
    },
  },
  created() {
    if(this.item.value!=""){
      this.item.checked = this.item.value;
    }else{
      this.item.checked = false;
    }
  },
};
</script>
