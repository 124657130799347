<template>
  <div>
    <div class="tags">
      <!-- 遍历 tags 显示标签 -->
      <el-tag
        :key="index"
        v-for="(tag, index) in tags"
        closable
        :disabled="item.disabled"
        :disable-transitions="false"
        style="margin-right: 10px;margin-top: 10px;"
        @close="removeTag(tag, 'tags')"
      >
        <!-- 可点击的标签文本 -->
        <span @click="editTag(tags, tag, index)" style="cursor: pointer">{{
          tag
        }}</span>
        <!-- 添加新标签的按钮 -->
        <el-button
          type="primary"
          :disabled="item.disabled"
          style="padding: 0 2px; margin-left: 5px"
          size="small"
          @click="
            showTagInput('isInputVisible', 'tagInputRef', index, 'tagIndex')
          "
          >+</el-button
        >
        <!-- 向左移动标签的按钮 -->
        <el-button
          v-show="index > 0"
          type="primary"
          :disabled="item.disabled"
          style="padding: 0"
          size="small"
          icon="el-icon-arrow-left"
          @click="moveTagLeft(tags, index)"
        ></el-button>
        <!-- 向右移动标签的按钮 -->
        <el-button
          type="primary"
          :disabled="item.disabled"
          style="padding: 0"
          size="small"
          icon="el-icon-arrow-right"
          @click="moveTagRight(tags, index)"
        ></el-button>
      </el-tag>
      <!-- 添加新标签的输入框 -->
      <el-input
        class="input-new-tag"
        v-if="isInputVisible"
        :disabled="item.disabled"
        v-model="inputValue"
        ref="tagInputRef"
        size="small"
        @keyup.enter.native="
          confirmInput('inputValue', 'tags', 'isInputVisible')
        "
        @blur="confirmInput('inputValue', 'tags', 'isInputVisible')"
      ></el-input>
      <!-- 显示输入框的按钮 -->
      <el-button
        :disabled="item.disabled"
        v-else
        :class="tagStyle"
        size="small"
        @click="showInput('isInputVisible', 'tagInputRef')"
        >+ {{newText}} {{ tagTitle }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { EventBus } from "../config/eventBus";
export default {
  components: {},
  props: {
    item: Object,
    pageStatus: String,
    language:String
  },
  computed: {
    tagStyle() {
      if (this.tags.length > 0) {
        return "button-new-tag";
      }
      return "";
    },
  },
  data() {
    return {
      isInputVisible: false, // 控制输入框可见性的布尔值
      isButtonDisabled: false, // 控制按钮状态的布尔值
      inputValue: "", // 输入框的值
      tagTitle: "",
      tags: [], // 初始标签列表
      newText:this.language=='CN'?'新增':"New"
    };
  },
  methods: {
    sendTagDataHandle() {
      let data = {
        name: this.item.label,
        value: this.tags,
      };
      // console.info('item:',this.item)
      EventBus.$emit("sendTagDataEvent", data);
      // this.$emit("sendTagDataEvent", data);
    },
    // 显示输入框并聚焦
    showInput(isInputVisible, ref) {
      this[isInputVisible] = true;
      this.$nextTick(() => {
        this.$refs[ref].$refs.input.focus();
      });
    },
    // 在特定索引显示输入框并聚焦
    showTagInput(isInputVisible, ref, index, tagIndex) {
      this[tagIndex] = index + 1;
      this[isInputVisible] = true;
      this.$nextTick(() => {
        this.$refs[ref].$refs.input.focus();
      });
    },
    // 处理输入确认并将其添加到标签列表中
    confirmInput(value, arr, isVisible) {
      let inputValue = this[value];
      if (inputValue) {
        // 将输入值添加到指定索引或末尾
        if (
          this[arr + "Index"] !== undefined &&
          this[arr + "Index"] !== null &&
          this[arr + "Index"] !== "" &&
          parseInt(this[arr + "Index"]) > 0
        ) {
          this[arr].splice(this[arr + "Index"], 0, inputValue);
          this[arr + "Index"] = -1;
        } else {
          this[arr].push(inputValue);
        }
        this.sendTagDataHandle();
      }
      this[isVisible] = false;
      this[value] = "";
    },
    // 处理点击标签以编辑其值
    editTag(array, tagContent, index) {
      this.$prompt("修改值-->" + tagContent, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          array.splice(index, 1, value);
        })
        .catch(() => {});
    },
    // 向左移动标签
    moveTagLeft(array, index) {
      let tempTag = array[index - 1];
      this.$set(array, index - 1, array[index]);
      this.$set(array, index, tempTag);
    },
    // 向右移动标签
    moveTagRight(array, index) {
      let tempTag = array[index + 1];
      this.$set(array, index + 1, array[index]);
      this.$set(array, index, tempTag);
    },
    removeTag(index) {
      this.tags.splice(this.tags.indexOf(index), 1);
    },
  },
  created() {
    this.tagTitle = this.item.label;
    if (this.pageStatus != "Add") {
      // console.info("tagthis.item.value:", this.item.value.value)
      if(this.item.value.value != ""){
        this.tags = this.item.value;
      }
      if (this.item.label == "Botanical Name" ) {
        this.sendTagDataHandle();
      }
    }
  },
};
</script>

<style>
.tags {
  display: flex;
  justify: flex-start;
  flex-wrap: wrap;
  align-items: center;

}
.button-new-tag {
  margin-left: 10px;
}
</style>