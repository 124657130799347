<template>
  <div style="position: relative">
    <el-input
      type="textarea"
      v-model="localValue"
      :disabled="item.disabled"
      :placeholder="placeholderText(item)"
      clearable
      :autosize="{ minRows: 2, maxRows: 8 }"
      :style="textareaDefaultStyle"
      @change="inputChange"
    ></el-input>
    <template v-if="item.tips_value.length > 0">
      <el-popover
        placement="top-start"
        :content="item.tips_value"
        trigger="hover"
        style="position: absolute; right: 10px; top: 10px"
      >
        <el-button
          slot="reference"
          icon="el-icon-edit"
          size="small"
          type="primary"
          :disabled="item.disabled"
          @click="defaultValueHandle(item)"
          >Default</el-button
        >
      </el-popover>
    </template>
  </div>
</template>

<script>
import { EventBus } from "../config/eventBus";
export default {
  props: {
    item: Object,
    value: String,
    controlData: Object,
    pageStatus: String,
    selectProductType: String,
    language: String,
  },
  inject: ["getGeneralSpecificationDataMethod"],
  data() {
    return {
      localValue: this.value, // 将 prop 赋值给 data 属性
    };
  },
  watch: {
    value(newVal) {
      console.info("newVal:", newVal);

      this.localValue = newVal; // 当 prop 变化时，同步到 data 属性
    },
    localValue(newVal) {
      this.$emit("input", newVal); // 当 data 属性变化时，同步到父组件
    },
  },
  computed: {
    textareaDefaultStyle() {
      return this.item.tips_value
        ? "width: 100%; box-sizing: border-box; padding-right: 120px;"
        : "";
    },
  },
  mounted() {
    // this.tipsSetup();
  },
  created() {
    if (this.pageStatus == "Add") {
      this.localValue = "";
    } else {
      this.localValue = this.item.value;
    }
    this.chineseLanguageTipsSetup();
    EventBus.$on("sendTagDataEvent", this.tagDataEvent);
  },
  methods: {
    inputChange(val){
      console.info('this.item:',this.item)
      this.item.value = val;
    },
    placeholderText(item) {
      let text = "Please Input ";
      if (this.language == "CN") {
        text = "请输入 ";
      }
      return text + item.label;
    },
    defaultValueHandle(item) {
      this.localValue = item.tips_value;
    },
    tagDataEvent(data) {
      this.chineseLanguageTipsSetup();
      if (data.name == "Botanical Name") {
        let typeList = [
          "PCG-CSFMU-B",
          "PCG-CSFMU-S",
          "PCG",
          "OEXT",
          "CSFMU-B",
          "CSFMU-S",
          "F&V",
          "FMU-D",
          "HEXT",
        ];

        if (typeList.includes(this.controlData.list.product_type.value)) {
          let defaultValue = "ingredient";
          //botanical_name.join('_')
          if (data.value) {
            defaultValue = data.value.join("_");
          }

          let botanical_str =
            "Spray dried, fine powdered botanical extract of ${botanical_str} that has been extracted in a controlled temperature, full spectrum extraction process.";
          let replaceValue = botanical_str.replace(
            "${botanical_str}",
            defaultValue
          );
          this.item.tips_value = replaceValue;
        }

        
      }
    },
    chineseLanguageTipsSetup() {
      if (this.language == "CN") {
        console.info("this.item.label:", this.item.label);
        if (this.item.label == "产品说明") {
          let gsData = this.getGeneralSpecificationDataMethod();
          let tips = "";
          let tipsValue = "";
          if (
            this.selectProductType == "TY-EHXTO" ||
            this.selectProductType == "TY-SHXTO"
          ) {
            tips =
              "此产品是精制${common_other_name_str}经超临界二氧化碳提取而得。";
            let nameValue = gsData.list["common_other_name"].value;
            if (nameValue && nameValue.length > 0) {
              let joinName = nameValue.join("_");
              tipsValue = tips.replace(
                "${common_other_name_str}",
                joinName
              );
            }else{
              tipsValue =
              "此产品是精制经超临界二氧化碳提取而得。";
            }
          }
          if (this.selectProductType == "TY-P") {
            tips =
              "经有机认证的全谱工艺提取、喷雾干燥的粉末状 ${botanical_name_str}植物提取物。";
            let nameValue = gsData.list["botanical_name"].value;
            if (nameValue && nameValue.length > 0) {
              let joinName = nameValue.join("_");
              tipsValue = tips.replace("${botanical_name_str}", joinName);
            }else{
              tipsValue = "经有机认证的全谱工艺提取、喷雾干燥的粉末状植物提取物。";
            }
          }
          this.item.tips_value = tipsValue;

          // this.item.label = replaceValue;
        }
      }
    },
  },
};
</script>
