<template>
  <div>
    <el-input
      v-model="formattedValue"
      :placeholder="placeholderText(item)"
      :disabled="item.disabled"
      clearable
      @change="inputChange()"
      :style="getStyle()"
    >
      <template v-if="showControl(item)" #suffix>
        <el-popover
          placement="top-start"
          :content="item.tips_value"
          trigger="hover"
          style="text-align: center"
        >
          <el-button
            slot="reference"
            icon="el-icon-edit"
            size="small"
            type="primary"
            :disabled="item.disabled"
            @click="defaultValueHandle(item)"
            >{{ defaultText }}</el-button
          >
        </el-popover>
      </template>
    </el-input>
    <el-button v-if="getShowControl()" type="primary">Complete</el-button>
  </div>
</template>

<script>
import * as StructConvertUtils from "../config/structConvertUtils";
export default {
  props: {
    item: Object,
    controlData: Object,
    direction: String,
    pageStatus: String,
    selectProductType: String,
    language: String,
  },
  data() {
    return {
      formattedValue: "",
      defaultStyle: "width: 100%",
      notesStyle: "width:95%",
      defaultText: this.language == "CN" ? "默认" : "Default",
    };
  },
  watch: {
    item: {
      handler(newItem) {
        if (this.pageStatus === "Add") {
          return;
        }

        if (newItem.value instanceof Object) {
          this.formattedValue = "";
          console.info("newItem.value:", newItem.value);
          return;
        }

        if (typeof newItem.value === "boolean") {
          // 将布尔值转换为字符串
          this.formattedValue = newItem.value ? "1" : "0";
          console.warn(
            "item.value 是布尔值，已转换为字符串:",
            this.formattedValue
          );
        } else {
          // 如果是数组，进行转换，否则直接赋值
          this.formattedValue = Array.isArray(newItem.value)
            ? newItem.value.join(", ")
            : newItem.value;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    placeholderText(item) {
      let label = this.formatTitle(item.label);
      let placeholder = "Please Input ";
      if (this.language == "CN") {
        placeholder = "请输入 ";
      }
      return placeholder + label;
    },
    inputChange() {
      this.item.value = this.formattedValue;
    },
    getStyle() {
      if (this.getShowControl()) {
        return this.notesStyle;
      } else {
        return this.defaultStyle;
      }
    },
    getShowControl() {
      let flag = this.item.label == "Notes";
      return flag;
    },
    showDefaultButton() {},
    showControl(item) {
      return item?.tips_value?.length > 0;
    },
    defaultValueHandle(item) {
      this.item.value = item.tips_value;
      this.$forceUpdate();
    },
    formatTitle(title) {
      let resTitle = StructConvertUtils.formatTitle(title);
      return resTitle;
    },
    shelfLifeTipsSetup() {
      if (this.item.label == "Shelf Life") {
        let shelflife_number = this.controlData.list.shelflife_number.value;
        let tips_value =
          shelflife_number + " months unopened " + this.item.tips_value;
        this.item.tips_value = tips_value;
      }
      if (this.item.label == "保质期备注") {
        let shelflife_number = this.controlData.list.shelflife_number.value;
        let tips_value =
          "未开封情况下" +
          shelflife_number +
          "个月保质期。" +
          this.item.tips_value;
        this.item.tips_value = tips_value;
      }
    },
  },
  created() {
    this.shelfLifeTipsSetup();
    // console.info("input-this.item", this.item.value.value);
    if (typeof this.item.value === "boolean") {
      this.item.value = 1;
    }
  },
};
</script>
