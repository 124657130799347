<template>
  <div>
    <el-button
      type="primary"
      @click="recoverHandle"
      :disabled="optionButtonFlag != true"
      >{{ buttonText.recoverText }}</el-button
    >
    <el-button
      type="warning"
      @click="addVerifyListHandle"
      :disabled="optionButtonFlag"
      >{{ buttonText.addVerifyListText }}</el-button
    >
    <el-button
      type="success"
      @click="saveHandle"
      :loading="saveLoading"
      :disabled="optionButtonFlag"
      >{{ buttonText.saveText }}</el-button
    >
    <el-button
      type="danger"
      @click="verifyHandle"
      :loading="verifyLoading"
      :disabled="optionButtonFlag"
      >{{ buttonText.verifyText }}</el-button
    >
    <el-button
      type="primary"
      @click="previewHandle"
      :loading="previewPdfLoading"
      >{{ buttonText.previewPdfText }}</el-button
    >
    <el-button @click="backHomeClick">{{ buttonText.backHomeText }}</el-button>
  </div>
</template>
<script>
import * as StructConvertUtils from "./config/structConvertUtils";
import dayjs from "dayjs";
export default {
  props: {
    pageStatus: String,
    isPrintSignature: Boolean,
    language: String,
  },
  computed: {
    optionButtonFlag() {
      return this.pageStatus == "Detail";
    },
  },
  data() {
    return {
      saveLoading: false,
      verifyLoading: false,
      previewPdfLoading: false,
      buttonText: {
        recoverText:
          this.language == "CN" ? "恢复到此版本" : "Recover From This Version",
        addVerifyListText:
          this.language == "CN" ? "添加版本" : "Add Verify List",
        saveText: this.language == "CN" ? "保存" : "Save",
        verifyText: this.language == "CN" ? "审批" : "Verify",
        previewPdfText: this.language == "CN" ? "打印预览" : "Preview PDF",
        backHomeText: this.language == "CN" ? "返回列表" : "Back Home",
      },
    };
  },
  inject: [
    "getFormDataMethod",
    "loadDetailDataMethod",
    "loadRecoverDataMethod",
  ],
  methods: {
    getButtonTextByLanguage() {},
    addVerifyListHandle() {
      this.saveLoading = true;
      this.$confirm("Add To Verify List？", "Tips", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        let data = this.getFormDataMethod();
        let res = StructConvertUtils.transformSaveData(data);
        let params = this.getSaveData(res);
        params.revNumber = data.revisionData.length;

        this.$api.handleSpec
          .verifyListNewSpec(params)
          .then(() => {
            this.saveLoading = false;
            this.$message({
              type: "success",
              message: "Already added to the Verify list",
            });
          })
          .catch((err) => {
            this.saveLoading = false;
            console.log(err);
          });
      });
    },
    getSaveData(res) {
      const saveData = {
        id: this.$route.query.id,
        itemCode: res.base_info["item_code"],
        productType: res.base_info["product_type"],
        productName: res.base_info["product_name"],
        physicalProperties: res.base_info["physical_properties"],
        countryOfOrigin: res.general_specification["country_of_origin"],
        botanicalName: res.general_specification["botanical_name"].toString(),
        productDescription: res.base_info["product_description"],
        shelflifeNumber: res.other_notes["shelflife_number"],
        jsonValue: JSON.stringify(res),
      };

      // console.info("saveData:", saveData);
      return saveData;
    },

    saveHandle() {
      let data = this.getFormDataMethod();
      console.info("-------------------------");
      console.info("转前data：", data);
      let res = StructConvertUtils.transformSaveData(data, this.language);
      let baseInfoData = res.base_info;

      //处理 customefield

      let saveData = this.getSaveData(res);
      // console.info('data---:',data);
      console.info("res---:", res);
      // console.info("saveData--:",saveData);
      // 判断 productType 是否为空
      let msg = "";
      if (this.selectType == "") {
        msg = "Please Select Product Type!";
      }
      // 判断 itemCode 是否为空
      if (baseInfoData["item_code"] == "") {
        msg = "Please Input Item Code!";
      }
      if (msg != "") {
        this.$message({
          type: "error",
          message: msg,
        });
      }

      let params = { ...this.$route.query };
      // console.info("params:", params.id);
      this.saveLoading = true;
      console.info("saveData===:", saveData);
      if(params.isCopy==='true'){
        this.sendAdd(saveData);
      }else{
        if (params.id) {
          saveData.id = params.id;
          this.sendEdit(saveData);
        } else {
          
          this.sendAdd(saveData);
        }
      }
      
    },
    setupCustomFields(data) {
      console.info("dtaa:", data);
    },
    verifyHandle() {
      let verifyTips = "Are You Sure About Verify?";
      let tipsText = "Tips";
      if(this.language=='CN'){
        verifyTips = "确定要审核吗？"
        tipsText = "温馨提示";
      }
      this.$confirm(verifyTips, tipsText, {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        let data = this.getFormDataMethod();

        // 检查 revisionData
        let revision = data.revisionData[data.revisionData.length - 1];
        console.info("revision:", revision);
        let msg = "";
        if (revision.requestedBy.length == 0) {
          msg = "Requested By Can Not Be Empty!";
          if(this.language=='CN'){
            msg="'请求者'不能为空的!";
          }
        } else if (revision.itemsRevised == 0) {
          msg = "Revised Notes Can Not Be Empty!";
          if(this.language=='CN'){
            msg="修订说明不能为空的!";
          }
        } else if (revision.editedBy == 0) {
          msg = "Edited By Can Not Be Empty!";
          if(this.language=='CN'){
            msg="编辑者不能为空!";
          }
        } else if (revision.reason == 0) {
          msg = "Reason Can Not Be Empty!";
          if(this.language=='CN'){
            msg="修订原因不能为空!";
          }
        }
        if (msg != "") {
          this.$message({
            type: "error",
            message: msg,
          });
          return;
        }
        revision.isComplete = true;
        let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
        revision.approvedBy = userInfo.username;
        revision.verifyName = userInfo.username;
        revision.verifyTime = dayjs().format("YYYY-MM-DD HH:mm:ss");

        let res = StructConvertUtils.transformSaveData(data);
        res.revision = data.revisionData;
        let saveData = this.getSaveData(res);
        saveData.revNumber = data.revisionData.length;
        // console.info('saveData-rev:',saveData);
        this.verifyLoading = true;

        this.verifyNewSpec(saveData);
      });
    },
    sendAdd(params) {
      params.id=''
      let api="";
      if (this.language == "CN") {
        api = this.$api.handleSpec.addCnNewSpecItem;
      }else{
        api = this.$api.handleSpec.addNewSpecItem
      }
      api(params)
        .then(() => {
          this.saveLoading = false;
          this.$message({
            type: "success",
            message: "Add Success",
          });
        })
        .catch((err) => {
          this.saveLoading = false;
          console.log(err);
        });
    },
    sendEdit(params) {
      let api = "";
      if (this.language == "CN") {
        api = this.$api.handleSpec.editCnNewSpecItem;
      }else{ 
        api = this.$api.handleSpec.editNewSpecItem;
      }
      api(params)
        .then(() => {
          this.saveLoading = false;
          this.$message({
            type: "success",
            message: "Update Success",
          });
        })
        .catch((err) => {
          this.saveLoading = false;
          console.log(err);
        });
    },
    verifyNewSpec(params) {
      let api = "";
      if (this.language == "CN") {
        api = this.$api.handleSpec.verifyCnNewSpec(params);
      } else {
        api = this.$api.handleSpec.verifyNewSpec(params);
      }

      api
        .then(() => {
          this.verifyLoading = false;
          this.loadDetailDataMethod();
          this.$message({
            type: "success",
            message: "Update Success",
          });
        })
        .catch((err) => {
          this.verifyLoading = false;
          console.log(err);
        });
    },
    previewHandle() {
      let data = this.getFormDataMethod();
      let res = StructConvertUtils.transformSaveData(data);

      // 添加 revisionData
      res.revision_info = data.revisionData[data.revisionData.length - 2];

      let saveData = this.getSaveData(res);
      saveData.verified_time = data.verified_time;
      saveData.rev_number = data.rev_number;

      let versionNumber = "preview";
      let previewPdf = true; //编辑状态为 true
      if (this.pageStatus == "Detail") {
        previewPdf = false;
        versionNumber = "V_" + data["revNumber"] + ".0";
      }
      let signatureName = "";
      if (this.isPrintSignature) {
        signatureName = "_signature";
      }
      let previewName = "";
      if (previewPdf) {
        previewName = "_preview";
      }

      //预览后端需要的字段为 json_value
      let printTitle = JSON.parse(saveData.jsonValue).base_info.print_title;

      saveData.json_value = saveData.jsonValue;
      delete saveData.jsonValue;

      console.info("previewData:", saveData);
      let languageType = "US";
      if(this.language=='CN'){
        languageType = "China";
      }
      let params = {
        language: languageType,
        data: saveData,
        cutomer_signature: this.isPrintSignature,
        preview_pdf: previewPdf,
        file_name:
          saveData.itemCode + "_" + versionNumber + signatureName + previewName+"_"+printTitle, //item_code+version+signature
        response_type: "text",
      };

      this.previewPdfLoading = false;
      this.$axios
        .post("https://spec.dracoherbs.com/flask/generate_spec", params, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          let fileUrl =
            "https://spec.dracoherbs.com/download/" + response.data.file_name;
          setTimeout(function () {
            this.previewPdfLoading = true;
            window.open(fileUrl);
          }, 200);
        })
        .catch((error) => {
          this.previewPdfLoading = true;
          console.error(error);
        });
    },
    backHomeClick() {
      window.history.go(-1);
    },
    recoverHandle() {
      let msg = "Are you sure to restore from this version?";
      let tipsText = "Tips";
      let okText = "OK";
      let cancelText = "Cancel";
      if(this.language=='CN'){
        msg = "您确定要从这个版本还原吗?";
        tipsText = "温馨提示";
        okText = "确定";
        cancelText = "取消";
      }
      this.$confirm(msg, tipsText, {
        confirmButtonText: okText,
        cancelButtonText: cancelText,
        type: "warning",
      }).then(() => {
        this.loadRecoverDataMethod();
      });
    },
  },
};
</script>